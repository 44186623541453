<template>
  <div class="header-bar-wrap">
    <div class="header-bar">
      <div class="sctp-container sctp-flex sctp-flex-sb">
        <div>{{ $t('message.welcome') }}{{ $store.state.setting.helloWord }}
          <router-link to="/#">
            <i class="el-icon-s-home home-icon"></i>
          </router-link>
        </div>
        <div class="sctp-flex right-menu">
          <template v-if="!user">
            <router-link to="/login">
              <i class="el-icon-s-custom sctp-color-main mg-r5"></i>
              <span class="sctp-color-main">{{ $t('message.login') }}</span>
            </router-link>
            <router-link to='/register'>
              <i class="el-icon-edit-outline sctp-color-main mg-r5"></i>
              <span class="sctp-color-main">{{ $t('message.register') }}</span>
            </router-link>
          </template>
          <template v-else>
            <div class="user-menu">
              <div @click="onUserNameClick" class="el-dropdown-link">
                {{ user.usernice }}（{{user.account}}）
                <template v-if="user.userTypeStr">
                  <el-tag
                    size="mini"
                    :effect="user.userTypeStr.includes('未') ? 'dark' : 'light' "
                    :type="user.userTypeStr.includes('未') ? 'danger' : 'success'"
                  >
                    ({{ user.userTypeStr }})
                  </el-tag>
                </template>
              </div>
              <div class="user-menu-container">
                <div class="flex pad8">
                  <el-avatar
                    shape="circle"
                    class="mg-r10"
                    :size="65"
                    fit="cover"
                    :src="user.avatar"
                  ></el-avatar>
                  <div class="flex flex-col flex-sb pad-tb5 fc-info">
                    <div>用户ID：{{ user.userId }}</div>
                    <div>用户姓名：{{user.username}}</div>
                    <div>绑定手机：{{ user.phone }}</div>
                    <div>注册日期：{{ dateToString(user.registerTime, 'yyyy-MM-dd') }}</div>
                  </div>
                </div>
                <div class="nav-warp">
                  <div class="flex flex-sb pad-tb5">
                    <el-button @click="goPage(`/user-center`)" size="mini">用户中心</el-button>
                    <el-button @click="goPage(`/account/rechagre`)" size="mini">账号管理
                    </el-button>
                    <el-button @click="goPage(`/user/account/password`)" size="mini">修改密码
                    </el-button>
                  </div>
                  <div class="flex flex-sb pad-tb5">
                    <el-button @click="goPage(`/requirement/myrelease`)" size="mini">我的需求</el-button>
                    <el-button @click="goPage(`/shop/transaction`)" size="mini">交易管理</el-button>
                    <el-button @click="goPage(`/user/order`)" size="mini">我的订单</el-button>
                  </div>
                  <div class="pad-tb5">
                    <el-button style="width: 100%;" @click="logout()" type="danger" size="mini">
                      退出登录
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <router-link to='/shop'>
              <i class="el-icon-s-shop mg-r5"></i>
              <span v-if="!user || !user.shopRegister">{{ $t('message.merchantSettled') }}</span>
              <span v-else>{{ $t('message.merchantCenter') }}</span>
            </router-link>
            <router-link to='/shopping-cart'>
              <span class="flex flex-center">
                  <i class="el-icon-shopping-cart-2 mg-r5"></i>
                <span style="margin-left: -6px;margin-top: -13px;color: red;font-size: 10px"> {{ cartCount > 999?'999+':cartCount }}</span>

                <span style="margin-left: 5px">
                  {{
                    $t('message.shoppingCart')
                  }}
                </span>

              </span>
            </router-link>
            <router-link to='/message'>
              <span class="pr flex">
                <i class="el-icon-bell mg-r5"></i>
              </span>
              <span class="flex flex-center">
                  <span style="margin-left: -7px;margin-top: -12px;color: red;font-size: 10px">{{msgCount > 999?'999+':msgCount}}</span>
                {{
                  $t('message.notification')
                }}
              </span>
            </router-link>
          </template>
          <router-link to='/feedback'>
            <span><i class="el-icon-edit-outline mg-r5"></i>{{
                $t('message.userfeedback')
              }}</span>
          </router-link>
          <router-link :to="`/help-center`" target="_blank">
            <i class="el-icon-question mg-r5"></i>
            <span>{{ $t('message.help') }}</span>
          </router-link>
<!--          <el-dropdown size="medium"-->
<!--                       @command="changeLanguage"-->
<!--          >-->
<!--            <div class="el-dropdown-link">-->
<!--              {{ $t('message.languages') }}-->
<!--              <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--            </div>-->
<!--            <el-dropdown-menu slot="dropdown">-->
<!--              <el-dropdown-item-->
<!--                v-for="language in languages"-->
<!--                :key="language.value"-->
<!--                :command="language.value"-->
<!--              >-->
<!--                {{ language.label }}-->
<!--              </el-dropdown-item>-->
<!--            </el-dropdown-menu>-->
<!--          </el-dropdown>-->
          <span @click="openDialog"><i class="el-icon-service item-icon mg-r5"></i>客服</span>
          <a>
            <span>{{ $t('message.serviceHotline') }}：18057109608</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import language from '../../resources/languages'
import {cart, message} from "@/apis";

export default {
  name: 'GlobalHeader',
  components: {},
  data() {
    return {
      cartCount: 0,
      msgCount: 0,
      languages: [
        {label: '中文', value: 'zh-cn'},
        {label: 'English', value: 'en-us'}
      ],
    }
  },
  created() {
    // 创建之后添加购物车改变监听事件
    // this.$Bus.$off('cartCountChange')
    this.$Bus.$on('cartCountChange', () => {
      this.queryCartCount();
    });
  },
  computed: {},
  beforeMount() {
    // this.queryCartCount();
    this.$Bus.$emit('cartCountChange');
    this.$Bus.$emit('login');
  },
  methods: {
    onUserNameClick() {
      let {user} = this;
      if (!user) {
        return;
      }

        if (user.userType === 3) {
          this.goPage('/user/userinfo/businessverified')

        }
        if (user.userType === 2 || user.userType === 4) {
          // 跳转企业认证
          this.goPage('/user/userinfo/businessverified')
        } else if (user.userType === 1) {
          // 跳转个人认证
          this.goPage('/user/userinfo/verified')
        }

    },
    /**
     * 切换语言
     */
    changeLanguage(lang) {
      this.$store.commit('setting/setLanguage', lang)
      language.loadLanguageAsync(lang)
    },
    queryCartCount() {
      if (this.user) {
        cart.cartNumber({
          userId: this.user.userId
        }).then(res => {
          const {data = 0} = res
          this.cartCount = data.count
          this.msgCount = data.unReadMessageCount
        })
      }
    },
    // querymsgCount() {
    //   if (this.user) {
    //     message.messageNotice({
    //       userId: this.user.userId
    //     }).then(res => {
    //       const {data = 0} = res
    //       this.msgCount = data
    //     })
    //   }
    // },
    logout() {
      this.$store.commit('account/setUser', null);
      this.$router.push({path: '/login'});
      this.$Bus.$emit("logout");
    },
    openDialog() {
      let maxDom = document.getElementById('maxView');
      if (maxDom) {
        maxDom.click();
      }
      // let userId = this.user && this.user.userId || null;
      // let account = this.user && this.user.account || null;
      // let iWidth = 1000;
      // let iHeight = 600;
      // let iTop = (window.screen.availHeight - 30 - iHeight) / 2;
      // let iLeft = (window.screen.availWidth - 10 - iWidth) / 2;
      // window.open(
      //   `http://aiocs.ihzsr.cn/client?projid=1&website=sctpweb.ihzsr.cn&UUID=1fbb3dda-dcc4-4b4d-a512-be2c1cdf9447&userid=${userId}&nickname=${account}`, "在线客服",
      //   "width=" + iWidth + ", height=" + iHeight + ",top=" + iTop + ",left=" + iLeft
      //   + ",toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no,alwaysRaised=yes,depended=yes");
    }
  },
}
</script>

<style scoped lang="scss">
.header-bar-wrap {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 6;
  height: 40px;
  font: 12px/24px "微软雅黑";
  font-weight: 400;
  line-height: 40px;

  a {
    color: #333 !important;
  }

  .header-bar {
    position: relative;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #dfdfdf;
    background-color: #f7f7f7;

    .right-menu > * {
      display: flex;
      padding: 0 13px;
      justify-content: center;
      align-items: center;
    }

    .right-menu > *:hover {
      background-color: #fff;
    }

    .right-menu img {
      margin-right: 5px;
      height: 12px;
      width: 12px;
    }
  }
}

.dot {
  min-width: 6px;
  height: 6px;
  padding: 0 3px;
  border-radius: 100px;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  z-index: 30;
  background-color: #FA5A57;
}

.user-menu {
  position: relative;
  cursor: pointer;

  &:hover .user-menu-container {
    display: block;
  }

  .user-menu-container {
    display: none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    top: 40px;
    background: white;
    width: 240px;
    line-height: 1.2;

    .nav-warp {
      border-top: #ccc 1px dotted;
      padding: 10px;

      /deep/ {
        .el-button {
          padding: 8px 10px;
          font-size: 10px;
        }

        .el-button + .el-button {
          margin-left: 0;
        }
      }
    }
  }
}

.el-dropdown {
  font-size: inherit;
  cursor: pointer;
}

.el-dropdown-menu--medium .el-dropdown-menu__item {
  font-size: inherit;
}

.el-dropdown-menu {
  font: 12px/24px "微软雅黑";
}

.header-bar-wrap .home-icon {
  padding-left: 5px
}
</style>
